import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <p>
        Contact us at <a href="mailto:alexandrehpiva@gmail.com">here</a>
      </p>
    </footer>
  );
};

export default Footer;
