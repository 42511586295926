import React from 'react';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Home from '../components/Home';
import { Routes, Route } from 'react-router-dom';

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/test" element={<div>Test Home</div>} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/images" element={<div>404</div>} />
      <Route path="/images/test" element={<div>Test Image</div>} />
      <Route path="/" element={<Footer />} />
    </Routes>
  );
};

export default AppRouter;
